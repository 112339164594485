<template>
	<vue-line
    :mouseEvents="['mouseover']"
    v-on:mouseover="$emit('mouseover')"
  >
  	<vue-item>
      {{ segment.name }}
    </vue-item>

		<vue-item>
      <template v-if="segment.date_raw">
        {{ segment.date_raw | moment('LL') }}
      </template>
    </vue-item>

    <vue-item align="right">
      {{ segmentWs }}
    </vue-item>

    <vue-item align="right">
      {{ segmentTraffic }}
    </vue-item>

    <vue-item align="right">
      {{ segmentCount }}
    </vue-item>

    <vue-item align="right">
      <vue-element
        v-if="visibleCompare"
        type="label"
        :data="getComparisonData('yandex').transactions"
        labelNote="X"
      />
      <template v-else>
        {{ transactions.yandex }}
      </template>
    </vue-item>

    <vue-item align="right">
      <vue-element
          v-if="visibleCompare"
          type="label"
          :data="getComparisonData('google').transactions"
          labelNote="X"
      />
      <template v-else>
        {{ transactions.google }}
      </template>
    </vue-item>

    <vue-item align="right">
      {{ conversion.yandex }}
    </vue-item>

    <vue-item align="right">
      {{ conversion.google }}
    </vue-item>

    <vue-item align="right">
      <vue-element
          v-if="visibleCompare"
          type="label"
          :data="getComparisonData('yandex').transaction_revenue"
          labelNote="X"
      />
      <template v-else>
        {{ transactionRevenue.yandex }}
      </template>
    </vue-item>

    <vue-item align="right">
      <vue-element
          v-if="visibleCompare"
          type="label"
          :data="getComparisonData('google').transaction_revenue"
          labelNote="X"
      />
      <template v-else>
        {{ transactionRevenue.google }}
      </template>
    </vue-item>

		<vue-item class="segment_action" align="center">
      <div class="table-line__actions">
        <v-button
          v-bind="{
            height: '32px',
            type: 'text-base',
            paddingLeft: '6px',
            paddingRight: '6px',
            to: { query: routeDelete.query }
          }"
          v-tippy
          :content="$t(contentPath)['Remove this segment from the comparison']"
        >
          <icon
            v-bind="{
              name: 'delete',
              fill: '#9D99AA'
            }"
          />
        </v-button>
      </div>
    </vue-item>
	</vue-line>
</template>

<script>
import {roundNumber, generateRoute} from '@/function'

export default {
  props: {
    segment: {
      type: Object,
      default: function () {
        return {}
      }
    },

    activeSegment: {
      type: Object
    },

    searchSystem: {
      type: String,
      default: 'yandex'
    },

    activeSalesMetric: {
      type: Object,
      default: () => ({})
    },
  },

  data () {
    const contentPath = 'services.bi.comparison'

    return {
      contentPath
    }
  },

  computed: {
    routeDelete () {
      let newRoute = generateRoute(this.$route)

      return this.deleteParam({
        route: newRoute,
        type: this.segment.type,
        id: this.segment.id
      })
    },

    visibleCompare () {
      return this.activeSegment && this.activeSegment.id !== this.segment.id
    },

    segmentWs () {
      return this.formatCount(this.segment.ws)
    },

    segmentTraffic () {
      return this.formatCount(this.segment[this.searchSystem].traffic)
    },

    segmentCount () {
      return this.formatCount(this.segment.count)
    },

    transactionRevenue () {
      return {
        yandex: this.formatCount(this.segment.yandex.transaction_revenue),
        google: this.formatCount(this.segment.google.transaction_revenue)
      }
    },

    transactions () {
      return {
        yandex: this.formatCount(this.segment.yandex.transactions),
        google: this.formatCount(this.segment.google.transactions)
      }
    },

    conversion () {
      return {
        yandex: this.formatCount(this.segment.yandex.transactions, '%'),
        google: this.formatCount(this.segment.google.transactions, '%')
      }
    }
  },

  methods: {
    formatCount (count, label = '') {
      if (count !== null && count !== undefined) {
        return count.toLocaleString(undefined) + label
      } else if (count === 0 || !!count) {
        return count + label
      }
    },

    deleteParam ({ route, type, id }) {
      try {
        const activeFilter = route.query[`${type}_id`]

        if (Array.isArray(activeFilter)) {
          const index = route.query[`${type}_id`].indexOf(String(id))
          if (index > -1) {
            route.query[`${type}_id`].splice(index, 1)
          }
        } else {
          route.query[`${type}_id`] = undefined
        }
      } catch (_) {}

      return route
    },

    getComparisonData (searchSystem) {
      const comparisonFormula = (name, searchSystem) => {
        return roundNumber((this.segment[searchSystem][name] - this.activeSegment[searchSystem][name]) / this.activeSegment[searchSystem][name], 1) || 0
      }

      return {
        transaction_revenue: comparisonFormula('transaction_revenue', searchSystem) || 0,
        transactions: comparisonFormula('transactions', searchSystem) || 0
      }
    }
  }
}
</script>
