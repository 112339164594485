<template>
  <v-chart
      v-model="visibilityLinearCharts"
      lib="highcharts"
      dataforce
  />
</template>

<script>
export default {
  props: {
    segmentsListFrequency: {
      type: Object,
      default: function () {
        return {}
      }
    },

    urlFilters: {
      type: Object,
      default: function () {
        return {}
      }
    },

    activeSalesMetric: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },

  data: () => {
    const contentPath = 'services.bi.comparison'

    return {
      contentPath
    }
  },

  computed: {
    visibilityLinearCharts () {
      const series = []

      try {
        series.push({
          name: 'Яндекс',
          data: this.segmentsListFrequency.list.map(item => {
            try {
              return {
                name: item.name,
                y: item.yandex[this.activeSalesMetric.id]
              }
            } catch (_) {
              return undefined
            }
          }).filter(_ => _),
          colorIndex: 0
        })
      } catch (_) {
      }

      try {
        series.push({
          name: 'Google',
          data: this.segmentsListFrequency.list.map(item => {
            try {
              return {
                name: item.name,
                y: item.google[this.activeSalesMetric.id]
              }
            } catch (_) {
              return undefined
            }
          }).filter(_ => _),
          colorIndex: 1
        })
      } catch (_) {
      }

      return {
        chart: {
          type: 'column'
        },

        xAxis: {
          type: 'category',
          categories: this.segmentsListFrequency.list.map(item => item.name)
        },

        tooltip: {
          pointFormat: `
            <div style="line-height: 18px;">
                <span style="font-size: 16px; color:{point.color};">\u25CF</span> {series.name}: <b>{point.y}${this.activeSalesMetric.id === 'conversion' ? '%' : ''}</b>
            </div>
          `
        },

        series
      }
	  }
  }
}
</script>
