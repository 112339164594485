import ROUTE_NAME from '@/router/routeName'
import CONSTANTS from '@/const/'

import pages from '../../mixins/pages.js'
import chartCorrelationsSales from '../../components/correlationSalesChart/'
import tableCorrelationsSales from '../../components/correlationSalesTable/'

export default {
  mixins: [
    pages
  ],

  components: {
    'v-chart-correlations-sales': chartCorrelationsSales,
    'v-table-correlations-sales': tableCorrelationsSales
  },

  metaInfo () {
    return {
      title: `${this.title.primary} / ${this.title.secondary} / ${this.projectData.label} (${this.projectData.id})`
    }
  },

  computed: {
    /* Заголовки страницы */
    title () {
      return {
        primary: this.$t('routes.bi')[ROUTE_NAME.BI],
        secondary: `${this.$t('routes.bi')[ROUTE_NAME.BI_COMPARISON]} / ${this.$t('services.bi.comparison')['Sales correlations']}`
      }
    }
  },

  methods: {
    async initData () {
      if (this.hasSegments) {
        this.preloader = true

        let {
          visibility,
          category_id,
          group_id,
          query_group_id,
          date,
          search_system
        } = this.urlFilters

        try {
          await this.getFrequency({
            query: {
              project_id: this.activeProjectId,
              visibility,
              category_id,
              group_id,
              query_group_id,
              date_from: date.from,
              date_to: date.to,
              skip_regionality: 1,
              search_engine: search_system
            }
          })
        } catch (error) {
          this.$notify({type: 'error', title: error.message})
        }

        await this.getTrafficData()

        this.preloader = false
      }
    },

    async getTrafficData () {
      const {
        visibility,
        category_id,
        group_id,
        query_group_id,
        date
      } = this.urlFilters

      const query = {
        project_id: this.activeProjectId,
        visibility,
        date_from: date.from,
        date_to: date.to,
        category_id,
        group_id,
        query_group_id,
        /* Флаг для отключения ограничения */
        all: 1,
        skip_regionality: 1
      }

      try {
        await this.getTraffic({
          query: {
            ...query,
            search_engine: CONSTANTS.SEARCH_SYSTEM.YANDEX
          }
        })

        await this.getTraffic({
          query: {
            ...query,
            search_engine: CONSTANTS.SEARCH_SYSTEM.GOOGLE
          }
        })
      } catch (error) {
        this.$notify({type: 'error', title: error.message})
      }
    }
  },

  async created () {
    this.preloader = true

    if (!Object.keys(this.projectData).length) {
      try {
        await this.getProject({
          query: {
            project_id: this.activeProjectId,
            skip_regionality: 1
          }
        })
      } catch ({message}) {
        this.$notify({type: 'error', title: message})
      }
    }

    await this.initUpdates()
    await this.initMetrics()

    this.checkFiltersSegments(this.initData)

    this.pageLoader = false
  },

  watch: {
    async $route (from, to) {
      if (this.viewPage) {
        if (to.params.activeProject !== from.params.activeProject) {
          this.preloader = true
          await this.initMetrics()
          await this.initUpdates()
          await this.initData()
        } else if (
            to.query.date_from !== from.query.date_from ||
            JSON.stringify(from.query.category_id) !== JSON.stringify(to.query.category_id) ||
            JSON.stringify(from.query.group_id) !== JSON.stringify(to.query.group_id) ||
            JSON.stringify(from.query.query_group_id) !== JSON.stringify(to.query.query_group_id)
        ) {
          this.preloader = true
          await this.initData()
        }
      }
    }
  }
}
