var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"dashboard-container _column _pt"},[_c('v-page-project-title',{staticClass:"_mb-0 _border-bottom",attrs:{"primary":_vm.title.primary,"secondary":_vm.title.secondary,"breadcrumbs":"","project-data":_vm.projectData}}),(_vm.pageLoader)?_c('v-preloader',{attrs:{"static":"","clear":""}}):(_vm.viewPage)?[_c('div',{staticClass:"dashboard-block _mb-32"},[_c('v-filters',_vm._b({},'v-filters',{
          searchEngines: _vm.searchEngines,
          updates: _vm.updates,
          urlFilters: _vm.urlFilters,
          salesMetrics: _vm.salesMetrics
        },false))],1),_c('div',{staticClass:"dashboard-wrapper _border-none"},[(!_vm.hasSegments)?_c('v-error',_vm._b({},'v-error',{
          title: _vm.$t(_vm.contentPath)['No segments selected'],
          subtitle: _vm.$t(_vm.contentPath)['Select segments to compare']
        },false)):(_vm.frequency.has_data || _vm.preloader)?[_c('v-container',_vm._b({staticClass:"dashboard-block _mb-32 _ha"},'v-container',{
            title: _vm.activeSalesMetric.name,
            hasData: _vm.frequency.has_data,
            load: _vm.preloader
          },false),[_c('v-chart-correlations-sales',_vm._b({},'v-chart-correlations-sales',{
              segmentsListFrequency: _vm.segmentsListFrequency,
              urlFilters: _vm.urlFilters,
              activeSalesMetric: _vm.activeSalesMetric
            },false))],1),_c('v-container',_vm._b({staticClass:"dashboard-block _mb-32 _ha",scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('p',[_vm._v("Всего: "),_c('b',[_vm._v(_vm._s(_vm.segmentsListFrequency.list.length || 0))])])]},proxy:true}])},'v-container',{
            hasData: _vm.frequency.has_data,
            load: _vm.preloader
          },false),[_c('v-table-correlations-sales',_vm._b({},'v-table-correlations-sales',{
              segmentsListFrequency: _vm.segmentsListFrequency,
              urlFilters: _vm.urlFilters,
              isWestProject: _vm.isWestProject,
              activeSalesMetric: _vm.activeSalesMetric
            },false))],1)]:_c('v-error',_vm._b({},'v-error',{
          title: _vm.$t('modules.pages')['Error'],
          subtitle: _vm.$t('modules.pages')['No access to projects']
        },false))],2)]:_c('v-error',_vm._b({},'v-error',{
      title: _vm.$t('modules.pages')['Error'],
      subtitle: _vm.$t('modules.pages')['No access to projects']
    },false))],2)
}
var staticRenderFns = []

export { render, staticRenderFns }